@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl lg:text-4xl !important;
  }
  h2 {
    @apply text-xl lg:text-2xl !important;
  }
  h3 {
    @apply text-lg !important;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
